// core version + navigation, pagination modules:
import Swiper, { Navigation } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import Modal from 'bootstrap/js/dist/modal'

const swiper = new Swiper('.mySwiper', {
    modules: [Navigation],
    loop: false,
    /* navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }, */
});

const mainSliderSwiper = new Swiper('.focusNewsImageSwiper', {
    modules: [Navigation],
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});


const modal = new Modal(document.getElementById('video-focus-modal'));
const boxes = document.querySelectorAll('.video-focus-item');
var videoUrl = '';

boxes.forEach(box => {
    box.addEventListener('click', function handleClick(event) {
        videoUrl = box.dataset.url;
        document.getElementById('video_frame').src = videoUrl;        
        modal.show();
    });
});